import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/SEO"
import HeaderVideo from "../components/headerVideo"
import "../styles/landing-campaign.scss"
import "../styles/navidad.scss"
import "../styles/contacto.scss"
import { IconContext } from "react-icons"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import { Link } from "gatsby"

export default function NavidadesCiberseguras(props) {
  const [nombre, setNombre] = useState("")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState("")
  const [mensaje, setMensaje] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [mensajeError, setMensajeError] = useState("")
  const [respuesta, setRespuesta] = useState("")

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async e => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("leadsnavidad")
    //--> grab the generated token by the reCAPTCHA
    // Prepare the data for the server, specifically body-parser

    const data = JSON.stringify({ nombre, email, telefono, mensaje, result })
    // POST request to your server

    fetch(`${process.env.API_URL}/leadsnavidad-2022-s`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data, //--> this contains your data
    })
      .then(res => res.json())
      .then(data => {
        const success = data.success
        const score = data.score

        if (success && score >= 0.7) {
          setMensajeError("Gracias por ponerte en contacto con nosotros")
          setRespuesta("correcto")
          setNombre("")
          setEmail("")
          setTelefono("")
          setMensaje("")
          setIsChecked(false)
        } else {
          setMensajeError(
            "Ha habido un error en el envío del formulario, por favor inténtalo de nuevo"
          )
          setRespuesta("error")
        }
      })
  }

  return (
    <Layout>
      <Seo
        title="Prepárate para que nadie acabe ni con tu ilusión ni con tus datos"
        pathname={props.location.pathname}
        robots="index,follow"
        imagen={
          "https://bullhost.security/img/landings/navidad/navidad-bullhost.png"
        }
      />
      <div className="cabecera-fixed">
        <HeaderVideo titulo="Prepárate para que nadie acabe ni con tu ilusión ni con tus datos" />
        <div className="intro-campaign">
          <p>
            Nuestro equipo te ayuda en todo el proceso de solicitud y gestión de
            esta subvención. Tú te centras en tu negocio, y nosotros nos
            ocupamos de toda la gestión de la ayuda.
          </p>
        </div>
      </div>
      <div className="cuerpo-servicio">
        <section className="bloque-oscuro">
          <div className="overlay-contacto"></div>
          <div className="header-inicio limitador-inicio">
            <div className="detalle destacado">
              <p>ELLOS QUIEREN ROBAR TU INFO</p>
            </div>
            <div className="titulo">
              <p>
                En Bullhost sabemos qué es lo que necesitas y quieres para
                disfrutar de estas fiestas: tu familia, tus amigos, contar con
                tiempo para ellos, sentirte bien, volver a encontrarte con esas
                personas que tienes lejos...
              </p>
              <p>
                Pero también sabemos que hay quien pretende acabar con todos tus
                deseos: si hay algo que aprovechan los ciberdelincuentes son
                estas fechas señaladas y de relax para robarte todo. No dejemos
                que suceda.
              </p>
            </div>
          </div>
        </section>
        <section className="limitador contenido__servicio kdtablet">
          <div className="grid-landing">
            <div className="gridtexto navidad">
              <h2>¿Qué puedes hacer para evitarlo?</h2>
              <p>
                Protegerte es vital, pero no hay solución que garantice al 100%
                no ser atacado. ¿Te habían dicho que sí? Seamos sinceros,
                bloqueamos y monitorizamos cientos de miles de ataques al año,
                pero en un ataque además de la tecnología está el factor humano
                y... Todo el mundo puede tener un mal día. ¿Entonces?
              </p>
            </div>
            <div className="centrado-campaign betablet">
              <img
                src="/img/landings/navidad/gorro-con-cara.webp"
                alt="Navidad en Bullhost"
              ></img>
            </div>
          </div>
        </section>
        {/*BLOQUE MENSAJE*/}
        <section className="bloque-oscuro cta-navidad-background">
          <div className="overlay-contacto"></div>
          <div className="header-inicio limitador-inicio">
            <div className="titulo">
              <h2>
                Las empresas con información vital necesitan un paso más en sus
                sistemas de backup
              </h2>
            </div>
          </div>
        </section>
        {/* FIN BLOQUE MENSAJE */}
        {/*BLOQUE TEXTO*/}
        <section className="limitador contenido__servicio navidad">
          <div id="grid-entradas">
            <div className="row">
              <h2>Backups trabajando en equipo</h2>
              <p>Da un paso más.</p>
              <p>
                No hay sistema infalible: son varios sistemas los que garantizan
                la protección total de tus datos.
              </p>
              <p>
                Una <b>copia en local</b> bien gestionada te dará toda la
                rapidez necesaria en una recuperación, pero no sólo podrás
                confiar en tu infraestructura.
              </p>
              <p>
                Una <b>copia en la nube</b> te da seguridad y confianza, estamos
                nosotros protegiéndola.
              </p>
              <p>
                Y un <b>plan de recuperación ante desastres</b> (Disaster
                Recovery Plan) es la excelencia, porque con él se garantiza
                siempre la recuperación o continuación de los sistemas
                tecnológicos de vital importancia en tu organización.
              </p>
              <p>
                Consulta con nuestro equipo qué es lo que tienes que hacer para
                que nadie te robe la tranquilidad estas navidades.
              </p>
            </div>
          </div>
        </section>
        {/* FIN BLOQUE TEXTO */}
        {/* SECCION FORMULARIO */}
        <section className="limitador contenido__servicio">
          <h2>¿Quieres más información?</h2>
          <div className="bloque-noasistir">
            <div className="prueba-demo">
              <div className="cuerpo-contacto bloque-noasistir-div navidad">
                <div className="caja-formulario">
                  <h3>Quiero tener mis datos seguros</h3>
                  <form
                    className="form-contacto"
                    method="post"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <label htmlFor="nombre">Nombre</label>
                    <input
                      name="nombre"
                      placeholder="Nombre*"
                      required
                      value={nombre}
                      onChange={e => setNombre(e.target.value)}
                    />

                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email*"
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />

                    <label htmlFor="telefono">Teléfono</label>
                    <input
                      type="tel"
                      name="telefono"
                      placeholder="Teléfono*"
                      maxLength="14"
                      minLength="9"
                      required
                      value={telefono}
                      onChange={e => setTelefono(e.target.value)}
                    />

                    <textarea
                      name="mensaje"
                      id="mensaje"
                      placeholder="¿Quieres darnos algo de info?"
                      cols="30"
                      rows="5"
                      value={mensaje}
                      onChange={e => setMensaje(e.target.value)}
                    ></textarea>

                    <div className="caja-checkbox">
                      <input
                        type="checkbox"
                        id="privacidad"
                        name="privacidad"
                        // defaultChecked={isChecked}
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                      <label htmlFor="privacidad" className="label-check">
                        He leído y acepto la{" "}
                        <Link to="/politica-de-privacidad/">
                          política de privacidad
                        </Link>
                      </label>
                    </div>

                    <div
                      className={`mensaje-formulario
                                ${respuesta}                              
                            `}
                      dangerouslySetInnerHTML={{ __html: mensajeError }}
                    />

                    <IconContext.Provider value={{ color: "#1f1f1f" }}>
                      <input
                        type="submit"
                        value="Enviar"
                        className="boton-enviar"
                        disabled={!isChecked}
                      />
                    </IconContext.Provider>
                    <p className="texto-recaptcha">
                      Este sitio está protegido por reCAPTCHA y se aplican la{" "}
                      <a href="https://policies.google.com/privacy">
                        política de privacidad
                      </a>{" "}
                      y los{" "}
                      <a href="https://policies.google.com/terms">
                        términos del servicio
                      </a>{" "}
                      de Google.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div id="video">
            <h2>Bullhost Campaña de navidad 2022</h2>
            <div className="container-video">
              <iframe
                src="https://www.youtube.com/embed/w1eRTtigIHE?rel=0"
                title="YouTube"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
