import React from "react"
import VideoCabecera from "../../static/img/landings/navidad/landing-cabecera-navidad-bullhost-c.mp4"

export default function HeaderVideo(props) {
  return (
    <>
      <div className="cabecera-campaign">
        {/*<video className="cabecera-campaign__video" autoPlay muted="muted" playsinline="" loop="loop" preload="none" src={VideoCabecera} type="video/mp4"></video>*/}
        <div
          dangerouslySetInnerHTML={{
            __html: `<video class="cabecera-campaign__video" autoplay loop="loop" muted playsinline preload="none">
                    <source src=${VideoCabecera} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>`,
          }}
        />
        <h1>{props.titulo}</h1>
      </div>
    </>
  )
}
